/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import API from '../../../services';
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toastcom from '../../components/toastcom';

// eslint-disable-next-line react/prefer-stateless-function
class Modalbs extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], load: false, show: false, loggedin:true, mess:null, errortype:'success' };
  }

  componentDidMount() {}

  register = async(event) => { 
    axios.get(API.url+'event/register/'+event,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        // console.log('Response Status : ',response.status);
        if (response.status === 200){
          this.setState({ mess:"Congratulations, you have successfully participated in this event." }, () => toastbox('ttoast',3000));
        }
        else if(response.status === 201){
          this.setState({ mess:"Please make payment of contribution fee for this event." }, () => toastbox('ttoast',3000));
        } 
        // this.setState({  article: response.data.content }, () => this.setModal());
        // this.setState({ mess:response.data.content }, () => toastbox('ttoast',3000));
        
    })
    .catch((error) => { 
        // console.log(error);
        if (error.response.status == 401){ this.setState({loggedin: false}); }
        else{
          this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
        }
    });    
  }

  render() {

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

    let str; let comp; let date; let payment; let reserv;

    if (this.props.article !== null) {
      const data = this.props.article;

      if (data.desc !== undefined) {
        str = data.desc.replace('\\"', '"');
      }


        date = (
          <div>
            <strong> Event Date </strong>
            <div className="text-small text-secondary text-gold">{data.dates} - {data.time}</div>
            <strong> Chapter </strong>
            <div className="text-small text-secondary text-gold">{data.chapter}</div>
            <strong> Type </strong>
            <div className="text-small text-secondary text-gold">{data.type_desc}</div>
          </div>
        );
        payment = (
          <div className="text-left">
          <strong> Minimum Participants </strong>
            <div className="text-small text-secondary text-gold"> {data.minimum_participants}
          </div>
            <strong> Contribution Fee </strong>
            <div className="text-small text-secondary text-gold"> {API.formatNumber(data.fee)} </div>
            <strong> Status </strong>
            <div className="text-small text-secondary text-gold"> {API.formatNumber(data.done_desc)} </div>
          </div>
        );

        if (data.done == "0") { 
          reserv = <button onClick={() => this.register(data.id)} className="btn btn-primary btn-block btn-lg">Join</button>;

        }

        

      comp = (
        <Modal show={this.props.show} onHide={this.props.handleModal}>
          <Modal.Header>
            <a onClick={this.props.handleModal} data-bs-dismiss="modal">Close</a>
          </Modal.Header>
          <Modal.Body>
            <p><img src={data.image} width="100%" alt="img" /></p>
            <span className="h4_content"> {data.code} - {data.name}  <br/> <br/> </span>
            <div className="content" dangerouslySetInnerHTML={{ __html: str }} />
            <ul className="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
              <li>
                <div className="item">
                  <div className="in">
                  {date}
                  {payment}
                    {/* <div>
                      <strong> Event Date </strong>
                      <div className="text-small text-secondary text-gold">{data.date}</div>
                    </div>
                    <div className="text-left">
                      <strong>Payment Type</strong>
                      <div className="text-small text-secondary text-gold"> Booking Fee </div>
                    </div> */}
                  </div>
                </div>
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.props.handleModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.props.handleModal}>
                  Save Changes
                </Button> */}
            {reserv}
            <Toastcom data={this.state.mess} type={this.state.errortype}/>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <>
        {comp}
      </>
    );
  }
}

export default Modalbs;
