import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import Toastcom from '../components/toastcom';
import qs from 'qs';
// import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Cpassword extends Component {

    constructor(props){
      super(props);
      this.state = { loggedin:true, displaypass:true, load:false,
                     mess:null, errortype:'success' 
                   }
    }

    componentDidMount(){
       this.token_otentikasi();
    }

    token_otentikasi = async() => { 
      const stts = await API.otentikasi();
      if (stts === false){ this.setState({ loggedin:false }) }else{loggedin:true}
      console.log(this.state.loggedin);
    }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.set_password(); }
    }

    set_password = async() =>{
        
      let oldpass = document.getElementById("oldpassword").value;
      let newpass = document.getElementById("newpassword").value;
      let nilai = {old_pass: oldpass,new_pass: newpass};

      if (oldpass !== "" && newpass !== ""){

          this.setState({ load:false })

           axios.post(API.url+'customer/change_password',qs.stringify(nilai),{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
            .then((response) => {
                this.setState({ mess: "Password update successfull", errortype: 'success' }, () => toastbox('ttoast',3000));

                setTimeout(
                  () => this.setState({ loggedin: false }), 
                  3000
                );
            })
            .catch((error) => { 
              // console.log("Error : ", error);
              this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
              document.getElementById("oldpassword").value = "";
              document.getElementById("newpassword").value = "";
            }); 
            // axios

      }else{ 
             this.setState({ mess: 'username, password & otp required', errortype: 'error' }, () => toastbox('ttoast',3000));
             $("#username").focus(); 
          }
    }

  render() {

     let button;
     
     button = <a onClick={this.set_password} className="btn btn-primary btn-block btn-lg"> Set Password </a>;
     if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

    return (
      <>
        <div className="appHeader no-border transparent position-absolute">
          <div className="left">
            <a href="/profile" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle" />
        </div>
      {/* App Capsule */}
      <div id="appCapsule">
              <div className="section mt-2 text-center">
                <h1>Change password</h1>
              </div>
              <div className="section mb-5 p-2">
                <form action="index.html">
                  <div className="card">
                    <div className="card-body pb-1">

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email1"> Old Password </label>
                          <input type="password" className="form-control"id="oldpassword" onKeyUp={this.handleInput} placeholder="Your old password" />
                          <i className="clear-input">
                            <ion-icon name="close-circle" />
                          </i>
                        </div>
                      </div>

                      <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">New Password</label>
                        <input type="password" className="form-control" id="newpassword" onKeyUp={this.handleInput} placeholder="New password" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                      </div>

                    </div>
                  </div>
                  <div className="form-button-group transparent">
                    {button}
                    {/* <button type="submit" className="btn btn-primary btn-block btn-lg">Reset Password</button> */}
                  </div>
                </form>
              </div>
            </div>
      {/* * App Capsule */}
        {/* toast */}
        <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}
      </>
    );
  }
}

export default Cpassword;
