import React, { Component } from 'react';
import API from '../../services';
import Header from './comp/header';
import Wallet from './comp/wallet';
import News from './comp/news';
import Newsone from './comp/newsone';
import Bottommenu from '../components/bottommenu';
import BannerPopup from './comp/bannerpopup'; // Import komponen BannerPopup

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      data: [], 
      load: false, 
      loggedin: true, 
      deferredPrompt: null, 
      showPopup: true // Tambahkan state untuk mengontrol pop-up banner
    };
  }

  componentDidMount() {
    this.token_otentikasi();
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.addEventListener('popstate', this.handleBackButton);

    // Menampilkan pop-up banner selama 3 detik
    setTimeout(() => {
      this.setState({ showPopup: false });
    }, 7000);

    // Push initial state to the history to prevent exiting immediately
    window.history.pushState(null, null, window.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    this.setState({ deferredPrompt: e });
  }

  handleBackButton = (event) => {
    event.preventDefault();
    if (window.confirm("Are you sure you want to exit the app?")) {
      window.close();
    } else {
      // Prevent navigating back by pushing the state again
      window.history.pushState(null, null, window.location.pathname);
    }
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === true){ 
      console.log('Login Successful'); 
    } else if (stts === false){ 
      console.log("Login Failed");
      this.setState({ loggedin: false });
    }
  }

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  }

  render() {
    const { deferredPrompt, showPopup } = this.state;

    return (
      <>
        <Header loginstatus={this.state.loggedin}/>
        <div id="appCapsule">
          <Wallet/>
          <Newsone category="27" title="Partnership"/>
          <News category="0" title="Upcoming Event"/> 
          <News category="1" title="Completed Event"/>
          <News category="2" title="Upcoming News"/> 
        </div>
        {deferredPrompt && (
          <button onClick={this.handleInstallClick} className="install-button">
            Install PWA
          </button>
        )}
        {showPopup && <BannerPopup onClose={this.handleClosePopup} />}
        <Bottommenu/>
      </>
    );
  }
}

export default Home;
