import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modalbs from '../home/comp/modalbs';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, dataevent:null, datapromo:null, datanews:null, article:null, show:false, chapter:""};
  }

  componentDidMount(){
    this.decode();
  }

  decode = async() => { 
    try{
      const data = await API.decode();
      this.setState({ chapter: data.chapter }, () => {
        // Callback function after setState
        this.load_event(); this.load_event(1); this.load_article();
      });
    }
    catch(error){ this.load_event(); this.load_event(1); this.load_article();  }
  }

  load_article = (type=0) => {
    // console.log('Parameter : ',type);
    let limit = 25;
    
    axios.post(API.url+'article',{category:24, limit: 10, offset:0, orderby:"", order:"asc"},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      this.setState({ datanews: response.data.content.result });
    })
    .catch((error) => { 
        console.log(error);
    }); 
  }

  load_event = (type=0) => {
    // console.log('Parameter : ',type);
    let limit = 25;
    if (type === 0){type = "0";} // event
    else if (type === 1){ type = "1"; } // promo
    
    axios.post(API.url+'event',{chapter:this.state.chapter, limit: limit, offset:0, status:type},{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      if (type === "0"){ this.setState({ dataevent: response.data.content.result }); }
      else if (type === "1"){ this.setState({ datapromo: response.data.content.result }); }
      // else if (type === "2"){ this.setState({ datanews: response.data.content.result }); }
    })
    .catch((error) => { 
        console.log(error);
    }); 
  }

handleModal = async(event,type=0) => { 
  // console.log("Event : ",event);
    let url;
    if (type == 0){ url = API.url+'event/get_by_id/'+event;}else{ url = API.url+'article/get_by_id/'+event; }

    axios.get(url,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        this.setState({  article: response.data.content }, () => this.setModal());
    })
    .catch((error) => { 
        console.log(error);
    });    
}

setModal = async() =>{
  let stts;
  if (this.state.show === false){ stts = true;}else{ stts = false; }
  this.setState({ show:stts })
}

handleLinkClick = (url) => {
  window.open(url,'_blank');
};

  render() {
    // console.log('Data Event : ',this.state.dataevent);
    // console.log('Data Promo : ',this.state.datapromo);
    // console.log('Data News : ',this.state.datanews);
    let event; let promo; let news;

    if (this.state.datanews !== null){
      news =  this.state.datanews.map((dynamicData,index) => {
              return (
              <a key={index} onClick={() => this.handleLinkClick(dynamicData.text)} className="item">
              <div className="detail">
              <img src={dynamicData.image} alt="img" className="image-block imaged w48" />
              <div>
                <strong>{dynamicData.title}</strong>
                 <p> {dynamicData.date} </p>
              </div>
              </div>
              </a>          
                      );
                  });
    }

    if (this.state.datapromo !== null){
      promo =  this.state.datapromo.map((dynamicData,index) => {
              return (
              <a key={index} onClick={() => this.handleModal(dynamicData.id)} className="item">
              <div className="detail">
              <img src={dynamicData.image} alt="img" className="image-block imaged w48" />
              <div>
                <strong>{dynamicData.code} - {dynamicData.name}</strong>
                <p> {dynamicData.chapter} </p>
                <p> {dynamicData.dates} - {dynamicData.time} </p>
              </div>
              </div>
              </a>          
                      );
                  });
    }

    if (this.state.dataevent !== null){
      event =  this.state.dataevent.map((dynamicData,index) => {
              return (
              <a key={index} onClick={() => this.handleModal(dynamicData.id)} className="item">
              <div className="detail">
              <img src={dynamicData.image} alt="img" className="image-block imaged w48" />
              <div>
              <strong>{dynamicData.code} - {dynamicData.name}</strong>
              <p> {dynamicData.chapter} </p>
              <p> {dynamicData.dates} - {dynamicData.time} </p>
              </div>
              </div>
              </a>          
                      );
                  });
    }

    return (
      <>
        {/* <Meta/> */}
         {/* App Header */}
         <div className="appHeader">
          <div className="left">
            <a href="/" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            What's On
          </div>
        </div>
        {/* * App Header */}
        {/* Extra Header */}
        <div className="extraHeader pe-0 ps-0">
          <ul className="nav nav-tabs lined" role="tablist">

            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab" href="#events" role="tab">
                Upcoming
              </a>
            </li>
           
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#promo" role="tab">
                Complete
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#news" role="tab">
                News
              </a>
            </li>

          </ul>
        </div>
        {/* * Extra Header */}
        {/* App Capsule */}
        <div id="appCapsule" className="extra-header-active full-height">
          <div className="section tab-content mb-1">
            {/* Modal Basic */}
            <Modalbs show={this.state.show} handleModal={this.setModal} article={this.state.article} />
            {/* Modal Basic */}

            {/* news tab */}
            <div className="tab-pane fade" id="news" role="tabpanel">
              {/* News */}               
              <div className="transactions">
                {/* item */}
                {news}
                {/* * item */}
              </div>            
              {/* * News */}                 
            </div>
            {/* * news tab */}

            {/* promo tab */}
            <div className="tab-pane fade" id="promo" role="tabpanel">
              {/* News */}               
              <div className="transactions">
                {/* item */}
                {promo}
                {/* * item */}
              </div>
              {/* * promo tab */}
            </div>
            {/* events tab */}
            <div className="tab-pane fade show active" id="events" role="tabpanel">
              {/* News */}               
              <div className="transactions">
                {/* item */}
                {event}
                {/* * item */}
              </div>
              {/* * events tab */}
            </div>
          </div>
          {/* * App Capsule */}
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Event;
