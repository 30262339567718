/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import API from '../../../services';
import axios from 'axios';
import Modalbs from './modalbs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import Comcarousel from './comcarousel';

// eslint-disable-next-line react/prefer-stateless-function
class Newsone extends Component {

  constructor(props){
    super(props);
    this.state = { data:null,load:false,loggedin:true,article:null,show:false }
  }

  componentDidMount(){
    // console.log("Category : ",this.props.category);
    this.load_slider();
  }

  load_slider = () => {
      this.setState({ load:false });
      axios.get(API.url+'slider',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      .then((response) => {
          this.setState({ data: response.data.content.result,load:true });
          // console.log(response.data.content.result);
      })
      .catch((error) => { 
          console.log(error);
      }); 
  }

  handleModal = async(url) => { 

    window.open(url, '_blank');
    // console.log("Event : ",event);

      // axios.get(API.url+'article/get_by_id/'+event,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
      // .then((response) => {
      //     this.setState({  article: response.data.content }, () => this.setModal());
      // })
      // .catch((error) => { 
      //     console.log(error);
      // });    
  }

  setModal = async() =>{
    let stts;
    if (this.state.show === false){ stts = true;}else{ stts = false; }
    this.setState({ show:stts })
  }

  render() {    
    // console.log("Data state : ",this.state.data);
    let comp; let title;
    if (this.state.data !== null){ 
      title = <div className="section-heading padding">
                <h2 className="title">{this.props.title}</h2>
                {/* <a href="/article" className="link">View All</a> */}
              </div>; 
    }

    if (this.state.data !== null){
      comp =   this.state.data.map((dynamicData,index) => {
                return (
                  <SplideSlide key={index}>
                    <a onClick={() => this.handleModal(dynamicData.url)} className="item">
                    {/* <a onClick={() => this.show_modal(dynamicData.id)} className="item"> */}
                      <div className="blog-card">
                      <img src={dynamicData.image} alt="image" className="imaged w-100" />
                        {/* <div className="text">
                        <h4 className="title">{dynamicData.title}</h4>
                        </div> */}
                      </div>
                      </a>
                  </SplideSlide>               
                  );
              });
    }
    return (
     <>
     <div className="section full mt-4 mb-3">
        {title}

        {/* <Button variant="primary" onClick={this.handleModal}>
            Launch demo modal
          </Button> */}
        <Modalbs show={this.state.show} handleModal={this.setModal} article={this.state.article} />

        {/* Modal Basic */}
        {/* <Modalbasic article={this.state.articleid}/> */}
        {/* Modal Basic */}

        {/* carousel multiple */}
        <Splide options={ {
          perPage: 1,
          width:'100%',
          rewind: true,
          type: "loop",
          gap: 16,
          padding: 16,
          arrows: false,
          pagination: false,
          autoplay: true, // Menambahkan autoplay
          interval: 2500, // Interval waktu dalam milidetik (3000ms = 3 detik)
          breakpoints: {
            500: {
                perPage: 1
            },
            768: {
                perPage: 1
            },
            1200: {
                perPage: 1
            }
          }
        } }>
        {/* <Splide hasTrack={ false }> */}

{comp}

        </Splide>
        {/* * carousel multiple */}
       
      </div>
     </>
    );
  }
}

export default Newsone;
