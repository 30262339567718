import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modalbs from '../home/comp/modalbs';
import Badges from './comp/badges';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, data:[],loggedin:true};
  }

  componentDidMount(){
    this.token_otentikasi();
     
    //  console.log(API.url);
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
      this.load_event();
    }
  }

  load_event = () => {
    axios.get(API.url+'customer/ledger',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      this.setState({ data: response.data.content.result }); 
    })
    .catch((error) => { 
        console.log(error);
    }); 
}

  render() {
    // console.log('Data State : ',this.state.data);
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let comp;
    if (this.state.data !== null){

      comp = this.state.data.map((dynamicData,index) => {
        return (
          <a key={index} className="item">
          <div className="detail">
            {/* <img src="assets/img/sample/brand/1.jpg" alt="img" className="image-block imaged w48" /> */}
            <div>
              <p>{dynamicData.code} - {dynamicData.no}</p>
              <p>{dynamicData.date}</p> 
              <p>{dynamicData.time}</p> <hr/>
              <h5 className="price text-gold"> Point : {API.formatNumber(dynamicData.amount/50000)}</h5> 
            </div>
          </div>
          <div className="right">
            <div className="price text-gold">{API.formatNumber(dynamicData.amount)}</div>
          </div>
        </a>     
                );
       });
    }
   
    return (
      <>
        {/* <Meta/> */}
         {/* App Header */}
         <div className="appHeader">
          <div className="left">
            <a href="/profile" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle">
            Transaction History
          </div>
        </div>
        {/* * App Header */}
        {/* App Capsule */}
        <div id="appCapsule">
          {/* Transactions */}
          <div className="section mt-2">
            {/* <div className="section-title">Today</div> */}
            <div className="transactions">
              {/* item */}
              {comp}
            </div>
          </div>
          {/* * Transactions */}
         
        </div>
        {/* * App Capsule */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Transaction;
