import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import Toastcom from '../components/toastcom';
// import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Forgot extends Component {

    constructor(props){
      super(props);
      this.state = { loggedin:false, displaypass:false, displayotp:false, displayotplink:false, load:false,
                     mess:null, errortype:'success' 
                   }
      localStorage.removeItem('token');
    }

    componentDidMount(){
       // this.token_otentikasi();
      //  console.log(API.url);
    }

    handleInput=(e)=>{
      if (e.key === 'Enter'){ this.login(); }
    }

    counter_link = async() =>{
       setTimeout(
         () => this.setState({ displayotplink: true }), 
         30000
       );
    }

    req_otp = async() => {
      let user = document.getElementById("username").value;
      let nilai = {username: user};

      if (user !== ""){
          this.setState({ load:false, displayotplink:false })
          axios.post(API.url+'customer/req_otp',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                this.setState({ displaypass:true, displayotp:true })
                this.counter_link();

                this.setState({ mess: response.data.content, errortype: 'success' }, () => toastbox('ttoast',3000));
                // swal(response.data.content,"","success");
            })
            .catch((error) => { 
                // error.response.status
                // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
                
                this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ 
          this.setState({ mess: 'Username required', errortype: 'error' }, () => toastbox('ttoast',3000));
          $("#username").focus(); 
        }
    }

    set_password = async() =>{
        
      let user = document.getElementById("username").value;
      let pass = document.getElementById("password").value;
      let otp = document.getElementById("otp").value;
      let nilai = {username: user,new_password: pass,otp:otp};

      if (user !== "" && pass !== "" && otp !== ""){
          this.setState({ load:false })
          axios.post(API.url+'customer/forgot',nilai)
            .then((response) => {
                // console.log(response.data.content); 
                // toast.success(response.data.content,
                // {position: toast.POSITION.BOTTOM_CENTER,
                // autoClose: 2000,
                // hideProgressBar: false});

                this.setState({ mess: response.data.content, errortype: 'success' }, () => toastbox('ttoast',3000));
                this.setState({ loggedin:true })
                // alert('login berhasil');
            })
            .catch((error) => { 
                this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));
                // console.log("INi error : ",error.response.data.error);
                this.setState({ load:true })
                $("#username").focus();
            }); 

      }else{ 
             this.setState({ mess: 'username, password & otp required', errortype: 'error' }, () => toastbox('ttoast',3000));
             $("#username").focus(); 
          }
    }

  render() {

     let comp; let button; let otp; let otplink;
     if (this.state.displaypass === true){ 

      comp =  <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="email1">New Password</label>
                <input type="password" className="form-control" id="password" onKeyUp={this.handleInput} placeholder="password" />
                <i className="clear-input">
                  <ion-icon name="close-circle" />
                </i>
              </div>
            </div>;

      //  comp = <div className="form-floating mb-2">
      //         <input type="password" className="form-control" id="password" onKeyUp={this.handleInput} placeholder="password" />
      //         <label htmlFor="two"> New Password</label>
      //       </div>;
       button = <a onClick={this.set_password} className="btn btn-primary btn-block btn-lg"> Set Password </a>;
     }else{
       button = <a onClick={this.req_otp} className="btn btn-primary btn-block btn-lg"> Send OTP </a>;
     }

     if (this.state.displayotplink === true){
        otplink = <h4> Not received otp code? <br/> <a onClick={this.req_otp} style={{color: 'red'}}> Request again. </a> </h4>;
     }

     if (this.state.displayotp === true){
      //  otp =  <div className="form-floating mb-2">
      //         <input type="number" className="form-control" id="otp" placeholder="otp" />
      //         <label htmlFor="two"> OTP </label>
      //       </div>;

       otp = <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" htmlFor="email1">OTP</label>
                <input type="number" className="form-control" id="otp" placeholder="otp" />
                <i className="clear-input">
                  <ion-icon name="close-circle" />
                </i>
              </div>
            </div>;
     }

    if (this.state.loggedin === true){ return <Redirect to='/login'></Redirect>}

    return (
      <>
        <div className="appHeader no-border transparent position-absolute">
          <div className="left">
            <a href="/login" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" />
            </a>
          </div>
          <div className="pageTitle" />
        </div>
      {/* App Capsule */}
      <div id="appCapsule">
              <div className="section mt-2 text-center">
                <h1>Forgot password</h1>
              </div>
              <div className="section mb-5 p-2">
                <form action="index.html">
                  <div className="card">
                    <div className="card-body pb-1">

                      <div className="form-group basic">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email1"> E-mail / Phone</label>
                          <input type="email" className="form-control"id="username" onKeyUp={this.handleInput} placeholder="Your e-mail / phone" />
                          <i className="clear-input">
                            <ion-icon name="close-circle" />
                          </i>
                        </div>
                      </div>

                      {comp}
                      {otp}
                      

                    </div>
                  </div>
                  <div className="form-button-group transparent">
                    {button}
                    {/* <button type="submit" className="btn btn-primary btn-block btn-lg">Reset Password</button> */}
                  </div>
                </form>
              </div>
            </div>
      {/* * App Capsule */}
        {/* toast */}
        <Toastcom data={this.state.mess} type={this.state.errortype}/>
        {/* toast */}
      </>
    );
  }
}

export default Forgot;
