import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import API from '../../../services';

const BannerPopup = ({ onClose }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const response = await axios.get(API.url+'slider/splash'); // Ganti dengan endpoint API yang sesuai
        // console.log("Data splash : ",response.data.content.result[0].url);
        if (response.data.content.result[0].image) {
          setImageUrl(response.data.content.result[0].image);
        } else {
          setError('Image URL not found in response');
        }
      } catch (error) {
        console.error('Error fetching banner image:', error);
        setError('Error fetching image');
      } finally {
        setLoading(false);
      }
    };

    fetchBannerImage();
  }, []);

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        {loading && <div style={styles.loader}>Loading...</div>}
        {error && <div style={styles.error}>{error}</div>}
        {imageUrl && !loading && !error && (
          <img 
            src={imageUrl} 
            alt="Banner" 
            style={styles.image}
          />
        )}
        <button onClick={onClose} style={styles.closeButton}>×</button>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Latar belakang transparan dengan efek overlay
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '80%', // Atur ukuran gambar sesuai kebutuhan Anda
    height: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '24px',
    color: '#fff',
    cursor: 'pointer',
    zIndex: 1001,
  },
  loader: {
    color: '#fff',
    fontSize: '18px',
  },
  error: {
    color: '#fff',
    fontSize: '18px',
  }
};

export default BannerPopup;
