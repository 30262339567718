import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Register from "./container/register";

import Home from './container/home';
import Login from './container/login';
import Register from './container/register';
import Forgot from './container/forgot';
import Verify from "./container/verify";
import Reservation from './container/reservation';
import Event from "./container/event";
import Profile from "./container/profile";
import Profilesetting from "./container/profilesetting";
import Rhistory from "./container/rhistory";
import Notfound from "./container/notfound";
import Notif from "./container/notif";
import Redeem from "./container/redeem";
import Redeemhistory from "./container/redeemhistory";
import Pointhistory from "./container/pointhistory";
import Transaction from "./container/transaction";
import Payment from "./container/payment";
import Cpassword from "./container/cpassword";


import { Helmet } from "react-helmet";

// eslint-disable-next-line react/prefer-stateless-function
class Routes extends Component {
  render() {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <BrowserRouter>
      <Helmet>
        <title> Merciku: Mercedes Benz Community Indonesia | Merciku </title>
        <meta 
          name="ngunyah"
          content="Pilihan banyak menu dari restoran dan katering pilihan siap diantar ke tempatmu tanpa tambahan ongkir. Fokus kerja, urusan makan biar Ngunyah aja."
        />
      </Helmet>
        <Switch>
          {/* <Route path="/login" render={props => <Login {...props}/>}> <Login/> </Route> */}
          {/* <Route path="/" render={props => <Home {...props}/>}> <Home/> </Route> */}

          <Route path="/" exact> <Home /> </Route>
          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/cpassword" exact> <Cpassword /> </Route>
          <Route path="/event" exact> <Event /> </Route>
          <Route path="/profile" exact> <Profile /> </Route>
          <Route path="/profilesetting" exact> <Profilesetting /> </Route>
          <Route path="/rhistory" exact> <Rhistory /> </Route>
          <Route path="/notif" exact> <Notif /> </Route>
          <Route path="/redeem" exact> <Redeem /> </Route>
          <Route path="/redeemhistory" exact> <Redeemhistory /> </Route>
          <Route path="/pointhistory" exact> <Pointhistory /> </Route>
          <Route path="/transaction" exact> <Transaction /> </Route>

          <Route path="/verify/:custid" component={Verify} />
          <Route path="/payment/:custid" component={Payment} />
          <Route path="/reservation/:eventid" component={Reservation} />

          <Route component={Notfound} />

          {/* <Route path="/" component={Home} />
          <Route path="/shop" component={Shop} /> */}

        </Switch>
      </BrowserRouter>
    );
  }
}
export default Routes;
