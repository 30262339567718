import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../../services';
import swal from 'sweetalert';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Mtype extends Component {
  constructor(props) {
    super(props);
    this.state = {load: false, basic:[],silver:[],gold:[],platinum:[],bronze:[]};
  }

  componentDidMount(){
    this.load_user();
    this.load_user(1);
    this.load_user(2);
    this.load_user(3);
  }


  load_user = async(type=0) => {
  axios.get(API.url+'slider/mtype/'+type,{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
  .then((response) => {
    // console.log("Data load user : ",response.data.content);
    if (type === 0){ this.setState({ basic: response.data.content}); }
    else if (type === 1){ this.setState({ bronze: response.data.content}); }
    else if (type === 2){ this.setState({ silver: response.data.content}); }
    else if (type === 3){ this.setState({ gold: response.data.content}); }
    else if (type === 4){ this.setState({ platinum: response.data.content}); }
  
      
  })
  .catch((error) => { }); 
  }

  render() {
    
    let basic; let silver; let gold; let platinum; let bronze;
    
    if (this.state.basic.desc !== undefined){ basic = this.state.basic.desc; }
    if (this.state.silver.desc !== undefined){ silver = this.state.silver.desc; }
    if (this.state.gold.desc !== undefined){ gold = this.state.gold.desc; }
    if (this.state.platinum.desc !== undefined){ platinum = this.state.platinum.desc; }
    if (this.state.bronze.desc !== undefined){ bronze = this.state.bronze.desc; }
    return (
      <>
        {/* <Meta/> */} 
        <div className="section mt-1">
          <div className="section-title">Membership Benefit</div>
          <div className="card">
            <div className="card-body pt-0">
              <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#basic" role="tab">
                    Basic
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#bronze" role="tab">
                    Bronze
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#silver" role="tab">
                    Silver
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#gold" role="tab">
                    Gold
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#platinum" role="tab">
                    Platinum
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-2">
                <div className="tab-pane fade show active" id="basic" role="tabpanel">
                  {/* Spend Rp. 10.000.000 more to reach SILVER */}
                  {basic}
                </div>
                <div className="tab-pane fade show active" id="bronze" role="tabpanel">
                  {/* Spend Rp. 10.000.000 more to reach SILVER */}
                  {bronze}
                </div>
                <div className="tab-pane fade" id="silver" role="tabpanel">
                  {/* SILVER MEMBER - Spend Rp. 10.000.000 in a year then enjoy its benefits at time its achieved and for the next year. Discount 5%. */}
                  {silver}
                </div>
                <div className="tab-pane fade" id="gold" role="tabpanel">
                  {/* GOLD MEMBER - Spend Rp. 50.000.000 in a year then enjoy its benefits at time its achieved and for the next year. Discount 10%. */}
                  {gold}
                </div>
                <div className="tab-pane fade" id="platinum" role="tabpanel">
                  {/* PLATINUM MEMBER - Spend Rp. 500.000.000 in a year then enjoy its benefits at time its achieved and for the next year. Discount 15%. */}
                  {platinum}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Mtype;
