import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mtype from './comp/mtype';
// import Meta from '../components/meta';
// import OneSignal from 'react-onesignal';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {userid:null,wano:null,load: false, property:null, data:null, point:0, user:null, show:false, loggedin:true};
  }

  componentDidMount(){
    this.token_otentikasi();
  }

  token_otentikasi = async() => { 
    const stts = await API.otentikasi();
    if (stts === false){ this.setState({ loggedin:false }) }else{
    this.get_property();
    this.decode();
    }
  }

  decode = async() => { 
    try{
      const data = await API.decode();
      this.setState({
        userid:data.userid
        },() => { this.get_chapter(data.chapter); });
    }
    catch(error){ this.load_event(); this.load_event(1); this.load_article();  }
  }

  get_chapter = async chapter =>{
    //  console.log("token : ",localStorage.getItem('token'));
       this.setState({ load:true });
        axios.get(API.url+'chapter/get_by_id/'+chapter, { headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            // console.log("Hasil data chapter : ",response.data.content.phone);
            this.setState({ wano:response.data.content.phone });
        })
        .catch((err) => { 
         }); 
  }

  get_property = async event =>{
    //  console.log("token : ",localStorage.getItem('token'));
       this.setState({ load:true });
        axios.get(API.url+'configuration/', { headers: {'X-Auth-Token': localStorage.getItem('token')}})
        .then((response) => { 
            this.setState({ property: response.data.content });
            this.load_balance();
            
            // console.log("Hasil response : ",response.data.content);
        })
        .catch((err) => { 
         }); 
  }

  load_balance = () => {
    this.setState({ load:false });
    axios.get(API.url+'customer/ledger',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
        this.setState({ point: response.data.content.point,load:true });
        this.load_user();
        // console.log(response.data.content);
    })
    .catch((error) => { 
        console.log(error);
    }); 
  }

  load_user = async() => {
    axios.get(API.url+'customer/get',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
    .then((response) => {
      // console.log("Data load user : ",response.data.content.result);
        this.setState({ user: response.data.content.result});
    })
    .catch((error) => { 
        // error.response.status
        // API.post_error_db(this.constructor.name.toLowerCase(),error.response.data.error,error.response.status);
        // swal(error.response.data.error, "", "error");
        // console.log(error);
    }); 
  }

  logout = async() =>{
    axios.get(API.url+'customer/logout/',{ headers: { 'X-Auth-Token': localStorage.getItem('token') } })
   .then((response) => {
     localStorage.removeItem('token'); localStorage.removeItem('os-user');
     this.setState({loggedin:false});
   })
   .catch((error) => { 
   }); 
 }

  render() {
    
    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}

    let targetURL;
    if (this.state.wano !== null){
     targetURL = `whatsapp://send?text=Hello,${this.state.property.name}. Terima kasih dan mohon ditunggu&phone=+${this.state.wano}`;
    }

    if (this.state.loggedin === false){ return <Redirect to='/login'></Redirect>}
    let name; let mtype="-"; let expiry="-"; let code="";
    let color="card-block mb-2";

    if (this.state.user != null){
      name = this.state.user.first_name.toUpperCase();
      code = this.state.user.quinos_id;
      mtype = this.state.user.mtype;
      if (this.state.user.expired_format !== null){
        expiry = this.state.user.expired_format;
      }
      // color change
      if (mtype == "2"){ mtype = "SILVER"; color = "card-block mb-2 silver-block"; }
      else if(mtype == "3"){ mtype = "GOLD"; color = "card-block mb-2 gold-block"; }
      else if(mtype == "4"){ mtype = "PLATINUM"; color = "card-block mb-2 platinum-block"; }
      else if(mtype == "1"){ mtype = "BRONZE"; color = "card-block mb-2 bronze-block"; }
      else if(mtype == "0"){ mtype = "BASIC"; color = "card-block mb-2 basic-block"; }

      // console.log('Type : ',this.state.user.mtype);
    }
    return (
      <>
        {/* <Meta/> */}

       {/* App Header */}
       <div className="appHeader no-border transparent position-absolute">
          <div className="left">
            <a href="/" className="headerButton goBack">
              <ion-icon name="chevron-back-outline" /> Back
            </a>
          </div>
       </div>
        {/* * App Header */}

         {/* App Capsule */}
      <div id="appCapsule">
        <div className="section mt-2">
          {/* card block */}
          <div className={color}>
            <div className="card-main">
              <div className="balance">
                <span className="label">Points</span>
                <a href="/transaction"> <h1 className="title"> {this.state.point} </h1> </a>
              </div>
              <div className="in">
                <div className="card-number">
                  <span className="label">Name</span>
                  {name} <br/> {code}
                </div>
                <div className="bottom">
                  <div className="card-expiry">
                    <span className="label">MEMBERSHIP</span>
                    {mtype}
                  </div>
                  <div className="card-ccv">
                    <span className="label">EXPIRY</span>
                    {expiry}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* * card block */}
        </div>

        {/* Mtype */}
        <Mtype/>
        {/* Mtype */}

        <div className="section mt-2">
          <div className="section-title">Account</div>
        </div>
        <ul className="listview image-listview inset mb-2">
          <li>
            <a href="/profilesetting" className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="person-outline" />
              </div>
              <div className="in">
                My Profile
              </div>
            </a>
          </li>
          <li>
            <a href={`/payment/${this.state.userid}`} className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="receipt-outline" />
              </div>
              <div className="in">
                Payment Confirmation
              </div>
            </a>
          </li>
          <li>
            <a href="/rhistory" className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="reorder-four-outline" />
              </div>
              <div className="in">
                My Event History
              </div>
            </a>
          </li>
          <li>
            <a href="/transaction" className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="receipt-outline" />
              </div>
              <div className="in">
                My Transaction History
              </div>
            </a>
          </li>
          <li>
            <a href="/redeemhistory" className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="receipt-outline" />
              </div>
              <div className="in">
                My Redeem History
              </div>
            </a>
          </li>
          <li>
            <a href="/cpassword" className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="reorder-four-outline" />
              </div>
              <div className="in">
                Change Password
              </div>
            </a>
          </li>
          <li>
            <a href={targetURL} className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="logo-whatsapp" />
              </div>
              <div className="in">
                Live Chat
              </div>
            </a>
          </li>
          <li>
            <a onClick={this.logout} className="item">
              <div className="icon-box bg-primary">
                <ion-icon name="log-out-outline" />
              </div>
              <div className="in">
                Logout
              </div>
            </a>
          </li>
        </ul>
      </div>
      {/* * App Capsule */}
      </>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default Profile;
