import React, { Component, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import API from '../../services';
import swal from 'sweetalert';
import axios from 'axios';
import $ from 'jquery';
import Toastcom from '../components/toastcom';
import Select from 'react-select';
import qs from 'qs';
// import Meta from '../components/meta';

// import ActionType from '../../redux/reducer/globalActionType';

class Payment extends Component {

    constructor(props){
      super(props);
      this.state = {data:[], backlink:"login", databank:[], bankdest:null, amount:0, billingcode:null, load:false, mess:null, errortype:'success', displaybutton:false }
    }

    componentDidMount(){
       this.get_ticket();
       this.get_bank();
       this.cek_backlink();
    }

    cek_backlink = async() => { 
      const stts = await API.otentikasi();
      if (stts === true){ this.setState({ backlink:"home" }) }
    }

    get_ticket = async() => {
          axios.get(API.url+'customer/ticket_list/'+this.props.match.params.custid)
            .then((response) => {
                this.setState({
                    data:response.data.content.result
                },() => {
                  // this.req_otp();
                });
            })
            .catch((error) => { 
                this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));   
            }); 
     }

    get_bank = async() => {
      axios.get(API.url+'chapter/get_by_customer/'+this.props.match.params.custid)
        .then((response) => {
            this.setState({
                databank:response.data.content.bank_list
            },() => {
              // this.req_otp();
            });
        })
        .catch((error) => { 
            this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));   
        }); 
    }

    handleChange = (selectedOption) => {
      this.setState({billingcode:selectedOption.value},() => {
        this.get_by_code(selectedOption.value);
      });
    };

    handleChangeBank = (selectedOption) => {
      this.setState({bankdest:selectedOption.value},() => {});
    };

    get_by_code = async(code) => {
      axios.get(API.url+'customer/get_ticket/'+code)
        .then((response) => {
            this.setState({
                amount:response.data.content.amount,  displaybutton:true
            },() => {
              // this.req_otp();
            });
        })
        .catch((error) => { 
            this.setState({ mess: error.response.data.error, errortype: 'error' }, () => toastbox('ttoast',3000));   
        }); 
    }

    post = async() =>{

      this.setState({ displaybutton: false});
          
      let code = this.state.billingcode;
      let name = document.getElementById("accname").value;
      let acc = document.getElementById("accno").value;
      let sender_bank = document.getElementById("banksource").value;
      let amount = document.getElementById("amount").value;
      let bank = this.state.bankdest;
  
      let mess = null;
      if (mess === null){
       let requestBody = {ccode:code,sender_name: name,sender_acc: acc, sender_bank:sender_bank, sender_amount:amount, bank:bank}
        
        this.setState({ load:false })
        axios.post(API.url+'customer/payment_confirmation/'+this.props.match.params.custid,qs.stringify(requestBody))
          .then((response) => {
              this.setState({ mess: "Payment Confirmation Success", errortype: 'success' }, () => toastbox('ttoast',3000)); 
              setTimeout(() => window.location.reload(), 3000);
          })
          .catch((error) => {  
              this.setState({ mess: error.response.data.error, errortype: 'error', displaybutton:true}, () => toastbox('ttoast',3000));
          }); 
            // axios
      }else{ this.setState({ mess: mess, errortype: 'error',displaybutton:true }, () => toastbox('ttoast',3000)); }
    }

  render() {

    let backlink;
    if (this.state.backlink === 'login'){ backlink = <a href="/login" className="headerButton goBack"> <ion-icon name="chevron-back-outline" /> </a>;}
    else{ backlink = <a href="/profile" className="headerButton goBack"> <ion-icon name="chevron-back-outline" /> </a>; }

    let buttonverify;
    if (this.state.displaybutton === true){
      buttonverify = <button onClick={this.post} type="button" className="btn btn-primary btn-block btn-lg">Post</button>;
    }

      // Custom styles for react-select
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'blue' : 'gray',
        '&:hover': {
          borderColor: 'blue',
        },
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
        padding: '5px',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999, // Ensure dropdown is above other elements
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'lightgray' : 'black',
        '&:hover': {
          backgroundColor: '#161129',
        },
        color: state.isSelected ? 'black' : 'inherit',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'black',
      }),
    };

    return (
      <>
         {/* <Meta/> */}
                  <div>

          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              {backlink}
              {/* <a href="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline" />
              </a> */}
            </div>
            <div className="pageTitle" />
            {/* <div className="right">
              <a href="/login" className="headerButton">
                Login
              </a>
            </div> */}
          </div>

          <div id="appCapsule">
            <form>
            <div className="section mt-2 text-center">
              <h1> Payment Confirmation </h1>
            </div>
            <div className="section mb-5 p-2">
              
                <div className="card">
                  <div className="card-body">

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">Billing ID</label> <br/>
                        <Select
                          id='chapter'
                          className=''
                          placeholder="..."
                          onChange={this.handleChange}
                          options={this.state.data}
                          styles={customStyles}
                        />

                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Nama </label>
                        <input type="text" className="form-control" id="accname" placeholder="" required />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> No Rekening </label>
                        <input type="number" className="form-control" id="accno" placeholder="" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Bank Pengirim </label>
                        <input type="text" className="form-control" id="banksource" placeholder="BCA, BSI, BNI, BRI, MANDIRI" />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1"> Jumlah </label>
                        <input type="number" className="form-control" id="amount" placeholder="0" value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" htmlFor="email1">Bank Tujuan</label> <br/>
                        <Select
                          id='ckab'
                          className=''
                          placeholder="..."
                          options={this.state.databank}
                          onChange={this.handleChangeBank}
                          styles={customStyles}
                        />

                        <i className="clear-input">
                          <ion-icon name="close-circle" />
                        </i>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="form-button-group transparent">
                {buttonverify}
                  {/* <button onClick={this.login} type="button" className="btn btn-primary btn-block btn-lg">Register</button> */}
                </div>
              
            </div>
            </form>
          </div>

          {/* toast */}
          <Toastcom data={this.state.mess} type={this.state.errortype}/>
          {/* toast */}

          </div>
      </>
    );
  }
}

export default Payment;
